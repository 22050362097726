import React from "react";
import Schedule from "./Schedule";
import {
  CheckCircle,
  Phone,
  Share2,
} from "feather-icons-react/build/IconComponents";

const ListBoxDetailsCellPhone = ({
  point,
  actualLocation,
  onClickBack,
  onClickShare,
  isOpen,
  allAdditionalServices,
}) => {
  const onClickMapsButton = () => {
    window.open(
      `https://www.google.cl/maps/dir/${actualLocation}/${point.address}/`,
      "_blank"
    );
  };
  const isPointTypeContainsCopec = point.typeAgency.name
    .toString()
    .toLowerCase()
    .includes("copec");
  const RenderSchelude = ({ point }) => {
    return (
      <>
        {point.other_location_info && (
          <div className="mb-2">
            <span className="fs-12px fw-800 fst-italic">
              Referencia de ubicación:{" "}
            </span>
            <span className="fs-12px fst-italic fw-600">
              {point.other_location_info}
            </span>
          </div>
        )}
        <div className="px-2 pb-2">
          <div className="pb-3">
            <Schedule
              scheduleDetail={point.scheduleDetail}
              is24Hours={point.is24Hours}
            />
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2">
              <button
                className="btn btn-options w-100 details fs-14px fw-700"
                onClick={onClickMapsButton}
              >
                <img
                  src="/assets/directions-icon.svg"
                  alt="map-icon"
                  className="me-2"
                  style={{ width: "16px", height: "16px" }}
                />
                Cómo llegar
              </button>
              <button
                className="btn btn-options  w-100 details fs-14px fw-700"
                onClick={() => {
                  onClickShare({
                    id: point.typeAgency.code,
                    title: point.name,
                    address: point.address,
                  });
                  onClickBack();
                }}
              >
                <Share2 size={16} className="me-2" />
                Compartir
              </button>
            </div>
            <div>
              {point.phone.isVisible && (
                <button
                  className="btn btn-options-white  w-100 details fs-14px fw-700"
                  onClick={() =>
                    (window.location.href = "tel:" + point.phone.phoneNumber)
                  }
                >
                  <Phone size={16} className="me-2" />
                  Llamar al Punto
                </button>
              )}
            </div>
          </div>
          <div>
            {point.clientServices?.length > 0 && (
              <div className="mb-1 mt-3">
                <span className="fw-800 fs-14px">Servicios disponibles</span>
              </div>
            )}
            {
              <div className="d-flex flex-column gap-1 mb-1">
                {point.clientServices?.map((service) => (
                  <div
                    key={service.id}
                    className="d-flex gap-2 align-items-center"
                  >
                    <CheckCircle className="color-text-skyblue" size={18} />
                    <span className="fs-14px">{service.name}</span>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </>
    );
  };
  return (
    <div
      className={
        "point-details-cel-full-container hide-on-desktop-and-tablet" +
        (isOpen ? " open" : "")
      }
    >
      <div className={"point-details-cel-container" + (isOpen ? " open" : "")}>
        <div className="d-flex gap-2 align-items-center pb-3">
          <div className="icon-container " onClick={() => onClickBack()}>
            <img
              src="/assets/left-arrow.svg"
              alt="clock-icon"
              style={{ width: "24px", height: "24px" }}
            />
          </div>
          <span
            className="fs-14px fw-800 color-text-blue"
            onClick={() => onClickBack()}
          >
            Volver atrás
          </span>
        </div>

        <div className="h-100 overflow-auto pb-4">
          <div className="list-box-header mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start gap-2 align-items-start">
                <div className="icon-container">
                  {isPointTypeContainsCopec ? (
                    <img
                      src={
                        process.env.REACT_APP_IMG_URL +
                        "/map_markers/copec-1.png"
                      }
                      alt="store-icon"
                      className="icon-store"
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_IMG_URL +
                        "/map_markers/b-blue.png"
                      }
                      alt="store-icon"
                      className="icon-store"
                    />
                  )}
                </div>
                <span className="text-main">{point.name}</span>
              </div>
              <div className="d-flex justify-content-end  align-items-start">
                <span className="fs-6 fw-800 text-km color-text-orange ps-1">
                  a {point.distance}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex gap-2 mb-2">
            <div className="icon-container">
              <img
                src="/assets/marker-icon.svg"
                alt="store-icon"
                className="marker-icon"
              />
            </div>
            <span className="fs-6 fw-500">{point.address}</span>
          </div>

          <div className="d-flex gap-2 mb-2">
            <div className="icon-container">
              <img
                src="/assets/clock-icon.svg"
                alt="clock-icon"
                style={{ width: "24px", height: "24px" }}
              />
            </div>

            <span
              className={`fw-800 fs-6 border-radius-8px ${
                point.is24Hours && "px-2 bg-color-24-7"
              }`}
            >
              {point.is24Hours ? "Abierto 24/7" : point.schedule}
            </span>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-center gap-3 pb-2">
            {point.additionalServices?.map((service) => {
              const matchingService = allAdditionalServices?.find(
                (allAdditionalService) =>
                  allAdditionalService.code === service.id
              );
              return (
                <img
                  key={service.id}
                  src={matchingService?.iconUrl}
                  alt="dimensions-icon"
                  className="dimensions-icon"
                  style={{ width: "24px", height: "24px" }}
                />
              );
            })}
          </div>

          <div>
            <RenderSchelude point={point} />

            <div className="d-flex gap-4 fs-12px justify-content-between fw-700">
              <div className="d-flex align-items-center gap-2">
                <img
                  src="/assets/dimensions-icon.svg"
                  alt="dimensions-icon"
                  className="dimensions-icon"
                  style={{ width: "16px", height: "16px" }}
                />
                <span>{point.dimensions}</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img
                  src="/assets/weight-icon.svg"
                  alt="weight-icon"
                  className="weight-icon"
                  style={{ width: "16px", height: "16px" }}
                />
                <span>{point.weight}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListBoxDetailsCellPhone;
