import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../package.json';

export const initDatadog = () => {

    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN, 
        site: 'datadoghq.com',
        service: packageJson.name,
        env: process.env.REACT_APP_ENVIRONMENT,
        version: packageJson.version,
        sessionSampleRate: 25,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'allow'
    });
}