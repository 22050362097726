import React from "react";

const Banner = () => {
  return (
    <div className=" d-flex gap-3 banner h-80px w-100 px-3 z-index-9999">
      <div className="me-auto">
        <a href="https://www.blue.cl/">
          <img
            src={process.env.REACT_APP_IMG_URL + "/logo/bx-blue.svg"}
            alt="map-icon"
            className="h-100"
            style={{ width: "80px" }}
          />
        </a>
      </div>
      <div className="align-self-center hide-on-cellphone">
        <button
          className="btn btn-banner-orange w-100"
          onClick={() => window.open("https://www.blue.cl/")}
        >
          Visitar www.blue.cl
        </button>
      </div>
      <div className="align-self-center">
        <button
          className="py-3 btn btn-banner-dynamic-white w-100"
          onClick={() => window.open("https://www.blue.cl/hazte-punto-blue/")}
        >
          Hazte Punto Blue Express
        </button>
      </div>
    </div>
  );
};

export default Banner;
