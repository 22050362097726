import React, { useEffect, useState, useRef } from "react";

const ComboBoxChecklist = ({ array, text, onClickItem }) => {
  const [isOpened, setIsOpened] = useState(false);
  let cbxRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!cbxRef.current.contains(e.target)) {
        setIsOpened(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="filter-combobox-container" ref={cbxRef}>
      <div>
        <button
          className="filter-combobox form-select form-select-sm fs-14px fw-light w-100 "
          onClick={() => setIsOpened(!isOpened)}
        >
          {text}
        </button>
      </div>
      {isOpened && (
        <div className="dropdown-menu-container">
          {array?.map((item) => {
            return (
              <DropdownItem
                key={item.code}
                text={item.description}
                setIsOpened={setIsOpened}
                isChecked={item.checked}
                code={item.code}
                onClick={onClickItem}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ text, setIsOpened, isChecked, code, onClick }) => {
  return (
    <div
      className={"dropdown-item-container " + (isChecked && "checked2")}
      onClick={() => {
        onClick(!isChecked, code);
        setIsOpened(false);
      }}
    >
      <div className="dropdown-item">
        <span>{text}</span>
      </div>
    </div>
  );
};

export default ComboBoxChecklist;
