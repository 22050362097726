const SwitchButton = ({ selectedButtonValue, setSelectedButtonValue }) => {
  return (
    <>
      <div className="container-switch-buttons ">
        <div className="d-flex justify-content-around" data-toggle="buttons">
          <div>
            <label
              className={
                "btn btn-switch " +
                (selectedButtonValue === "option-location" &&
                  " btn-switch-active")
              }
            >
              <input
                className="btn-check "
                type="radio"
                name="options"
                id="option1"
                value="option-location"
                autoComplete="off"
                checked={selectedButtonValue === "option-location"}
                onChange={(e) => setSelectedButtonValue(e.target.value)}
              />{" "}
              Ubicación
            </label>
          </div>
          <div>
            <label
              className={
                "btn btn-switch " +
                (selectedButtonValue === "option-point-name" &&
                  " btn-switch-active")
              }
            >
              <input
                className="btn-check"
                type="radio"
                name="options"
                id="option2"
                value="option-point-name"
                autoComplete="off"
                checked={selectedButtonValue === "option-point-name"}
                onChange={(e) => setSelectedButtonValue(e.target.value)}
              />{" "}
              Nombre Punto
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwitchButton;
