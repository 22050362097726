import React from "react";
import GeneralContainer from "./components/GeneralContainer";

const App = () => {
  if (!navigator.geolocation) {
    alert("tu navegador no tiene opción de geolocaclización");
    throw new Error("tu navegador no tiene opción de geolocaclización");
  }

  return (
    <div>
      <GeneralContainer />
    </div>
  );
};

export default App;
