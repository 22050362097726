import { Search } from "feather-icons-react/build/IconComponents";
import React, { Fragment, useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SwitchButton from "./SwitchButton";

const searchOptions = {
  componentRestrictions: { country: "cl" },
  language: "es",
  types: ["route", "locality"],
};

const SearchBar = ({
  updateMapPosition,
  selectedButtonValue,
  setSelectedButtonValue,
  text,
  setText,
}) => {
  const [latLng, setLatLng] = useState({});

  useEffect(() => {
    const center = {
      lat: latLng.lat,
      lng: latLng.lng,
    };

    if (typeof latLng.lat !== "undefined" && typeof latLng.lng !== "undefined")
      updateMapPosition(center);
  }, [latLng]);

  const handleSelect = (text) => {
    geocodeByAddress(text)
      .then((results) => {
        setText(results[0].formatted_address);
        getLatLng(results[0]).then((latLng) => setLatLng(latLng));
      })

      .catch((error) => console.error("Error", error));
  };

  return (
    <Fragment>
      {selectedButtonValue === "option-location" ? (
        <PlacesAutocomplete
          value={text}
          onChange={setText}
          debounce={1500}
          onSelect={handleSelect}
          searchOptions={searchOptions}
          highlightFirstSuggestion={text.length > 3}
          shouldFetchSuggestions={text.length > 3}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="d-flex flex-column align-items-center">
              <div className="search-bar w-100 position-relative">
                <input
                  {...getInputProps({
                    placeholder: "Ingresa una dirección, comuna o región",
                    className:
                      "ps-5 location-search-input form-control form-control-lg fs-14px fw-light",
                    type: "text",
                  })}
                />

                <Search size="20" className="search-icon" />
                <div className="hide-on-cellphone">
                  <SwitchButton
                    selectedButtonValue={selectedButtonValue}
                    setSelectedButtonValue={setSelectedButtonValue}
                  />
                </div>
              </div>
              {text.length > 3 && (
                <div className="autocomplete-dropdown-container fw-light autocomplete-container w-100 shadow">
                  {loading && <div>Cargando...</div>}
                  {suggestions?.map((suggestion) => {
                    const className =
                      "z-index-9999 " +
                      (suggestion.active
                        ? "suggestion-item--active py-2 px-3"
                        : "suggestion-item py-2 px-3");
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={suggestion.description}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="search-bar w-100 position-relative">
            <Search size="20" className="search-icon" />

            <input
              placeholder="Ingresa el nombre de un punto"
              className="ps-5 location-search-input form-control form-control-lg fs-14px fw-light pl-4"
              type="text"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
            <div className="hide-on-cellphone">
              <SwitchButton
                selectedButtonValue={selectedButtonValue}
                setSelectedButtonValue={setSelectedButtonValue}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchBar;
