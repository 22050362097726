import { WhatsappShareButton, WhatsappIcon } from "react-share";

import React from "react";
import { useState } from "react";

const SharePopUp = ({ onClose, infoToShare }) => {
  const [textCopyButton, setTextCopyButton] = useState("O copiar enlace");

  const shareUrl = `${process.env.REACT_APP_URL}?id=${infoToShare.id}&banner=true`;

  const title = `*${infoToShare.title}*\n${infoToShare.address}\n`;
  const separator = "\n";

  return (
    <div className="popup-container">
      <div className="popup">
        <span className="text-main">{"Compartir usando:"}</span>

        <div className="d-flex justify-content-around gap-4 my-4">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator={separator}
          >
            <WhatsappIcon
              size={48}
              round={true}
              className="icon-social-network"
            />
          </WhatsappShareButton>

          {/* <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon
              size={48}
              round={true}
              className="icon-social-network"
            />
          </FacebookShareButton>

          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={48} round className="icon-social-network" />
          </TwitterShareButton> */}
        </div>
        <div className="d-flex flex-column gap-2">
          <button
            className="btn btn-options w-100"
            onClick={() => {
              setTextCopyButton("¡Enlace copiado!");
              navigator.clipboard.writeText(shareUrl);
            }}
          >
            {textCopyButton}
          </button>
          <button className="btn btn-options-white w-100" onClick={onClose}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharePopUp;
