import { useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import {
  containerStyle,
  getFilteredAgenciesV2,
  mapStyle,
} from "../utilities/utilities";
import { memo } from "react";

import { useEffect } from "react";

const MapComponent = ({
  isMarkerShown,
  isDataLoaded,
  agencies,
  selectedAgencyId,
  center,

  setCenter,

  actualLocation,
  idActualInfoWindow,
  zoom,
  handleBluexMarkerClick,
  setIdActualInfoWindow,
  selectedMarkerSize,

  setAgenciesInsideBoundaries,

  map,
  setMap,

  setActualGeolocation,

  searchBarText,
  selectedSwitchButtonValue,

  pointTypesArray,
  clientServicesArray,
  filtersControllerArray,
  additionalServicesArray,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_APIKEY,
    language: "es",
    region: "CL",
  });

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClickMarker = (id) => {
    handleBluexMarkerClick(id);
    const newCenter = map.getCenter();
    setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
  };

  const onCloseInfoWindow = () => {
    setIdActualInfoWindow(-1);
    const newCenter = map.getCenter();
    setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
  };

  const handleBoundsChanged = () => {
    if (map && agencies) {
      const bounds = map.getBounds();

      if (!bounds) return;

      const northeast = bounds.getNorthEast();
      const southwest = bounds.getSouthWest();

      if (!northeast || !southwest) return;

      const agenciesInsideBoundaries = agencies.agencies?.filter((agency) => {
        const { latitude, longitude } = agency.address.geolocation;
        return (
          latitude < northeast.lat() &&
          latitude > southwest.lat() &&
          longitude < northeast.lng() &&
          longitude > southwest.lng()
        );
      });

      setAgenciesInsideBoundaries(agenciesInsideBoundaries);

      const newCenter = map.getCenter();
      setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };
  useEffect(() => {
    handleBoundsChanged();
  }, [agencies]);

  return isLoaded ? (
    <div className="position-relative w-100 h-100 map-container">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: center.lat,
          lng: center.lng,
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        id={map}
        options={{
          gestureHandling: "greedy",
          disableDefaultUI: true,
          zoomControl: true,
          styles: mapStyle,
          minZoom: 7,
          maxZoom: 18,
          rotateControl: true,
        }}
        onDragEnd={handleBoundsChanged}
        onZoomChanged={handleBoundsChanged}
      >
        <div className="w-100 h-100 d-flex align-items-end justify-content-end">
          <button
            className="center-button z-index-9999"
            onClick={() => {
              if (map) {
                setActualGeolocation();
              }
            }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="/assets/location-icon.svg"
                alt="location-icon"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </button>
        </div>
        {isMarkerShown && (
          <MarkerF
            position={actualLocation}
            icon={{
              url:
                process.env.REACT_APP_IMG_URL +
                "/map_markers/marker_blue_here.png",
              scaledSize: new window.google.maps.Size(51, 60),
            }}
          />
        )}
        {isDataLoaded &&
          agencies &&
          getFilteredAgenciesV2(
            agencies.agencies,
            agencies.agencies,
            null,
            pointTypesArray,
            clientServicesArray,
            filtersControllerArray,
            additionalServicesArray,
            selectedSwitchButtonValue,
            searchBarText
          )?.map((agency) => {
            const position = {
              lat: agency.address.geolocation.latitude,
              lng: agency.address.geolocation.longitude,
            };

            return (
              <div key={agency.agencyId}>
                <MarkerF
                  key={selectedMarkerSize}
                  position={position}
                  icon={{
                    url:
                      process.env.REACT_APP_IMG_URL +
                      (agency.typeAgency.name.toLowerCase().includes("copec")
                        ? "/map_markers/marker_copec.png"
                        : "/map_markers/marker-bluev2.png"),
                    scaledSize: new window.google.maps.Size(
                      selectedAgencyId === agency.agencyId
                        ? selectedMarkerSize * 0.85
                        : 34,
                      selectedAgencyId === agency.agencyId
                        ? selectedMarkerSize
                        : 40
                    ),
                  }}
                  onClick={() => onClickMarker(agency.agencyId)}
                />

                {agency.agencyId == idActualInfoWindow && (
                  <div>
                    <InfoWindowF
                      position={{
                        lat: position.lat + 0.0001,
                        lng: position.lng,
                      }}
                      onCloseClick={onCloseInfoWindow}
                    >
                      <>
                        <div>
                          <span className="fw-800 fs-20px color-text-blue">
                            {agency.agencyName}
                          </span>
                        </div>
                        <div>
                          <span className="color-text-blue fs-16px font-lato">
                            {`${agency.address.streetName} ${agency.address.streetNumber}, ${agency.address.state.name}`}
                          </span>
                        </div>
                      </>
                    </InfoWindowF>
                  </div>
                )}
              </div>
            );
          })}
      </GoogleMap>
    </div>
  ) : (
    <>
      <div className="p-4">
        <p className="fs-1">Cargando...</p>
      </div>
    </>
  );
};

export default memo(MapComponent);
