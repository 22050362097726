import moment from "moment-timezone";

export const getParamsFromUrl = () => {
  const bar = window.location.search;
  const params = new URLSearchParams(bar);
  const selectedId = params.get("id");
  const isBannerEnabled = params.get("banner");

  return { id: selectedId, isBannerEnabled: isBannerEnabled };
};

export const containerStyle = {
  width: "100%",
  height: "100%",
};

export const mapStyle = [
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
];

export const getActualSchedule = (agency) => {
  let day;
  switch (getCurrentDate().getDay()) {
    case 0:
      day = "Domingo";
      break;
    case 1:
      day = "Lunes";
      break;
    case 2:
      day = "Martes";
      break;
    case 3:
      day = "Miercoles";
      break;
    case 4:
      day = "Jueves";
      break;
    case 5:
      day = "Viernes";
      break;
    case 6:
      day = "Sabado";
      break;
  }

  return agency.schedules.attentions?.find((attention) => attention.day === day);
};

export const getFullDateString = (date) => {
  const fullDateString = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;
  return fullDateString;
};

export const getIsPointOpened = (newDate, schedules) => {
  const fullDateString = getFullDateString(newDate);

  let isOpened = false;

  let fromLunchDate = "";
  let toLunchDate = "";

  let closesSoonMinutes = 20;

  let today = "";
  switch (newDate.getDay()) {
    case 0:
      today = "Domingo";
      break;
    case 1:
      today = "Lunes";
      break;
    case 2:
      today = "Martes";
      break;
    case 3:
      today = "Miercoles";
      break;
    case 4:
      today = "Jueves";
      break;
    case 5:
      today = "Viernes";
      break;
    case 6:
      today = "Sabado";
      break;
    default:
      break;
  }

  const todaySchedule = schedules.attentions?.find(
    (attention) => attention.day === today
  );
  if (todaySchedule) {
    const fromDate = moment(
      `${fullDateString} ${todaySchedule.startTime}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );

    const toDate = moment(
      `${fullDateString} ${todaySchedule.endTime}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );

    fromLunchDate = todaySchedule.launchStartTime
      ? moment(
          `${fullDateString} ${todaySchedule.launchStartTime}:00`,
          "YYYY-MM-DD HH:mm:ss"
        )
      : "";

    toLunchDate = todaySchedule.launchEndTime
      ? moment(
          `${fullDateString} ${todaySchedule.launchEndTime}`,
          "YYYY-MM-DD HH:mm:ss"
        )
      : "";

    const toDateSoon = moment(
      new Date(toDate.toDate().getTime() - closesSoonMinutes * 60 * 1000),
      "YYYY-MM-DD HH:mm:ss"
    ).tz("America/Santiago");

    if (isNotNill(fromLunchDate) && isNotNill(toLunchDate)) {
      if (
        (newDate >= fromDate && newDate < fromLunchDate) ||
        (newDate >= toLunchDate && newDate < toDate)
      ) {
        isOpened = true;
      }
    } else {
      if (newDate >= fromDate && newDate < toDate) {
        isOpened = true;
      }
    }
  }

  return isOpened;
};

export const calculateSchedule = (newDate, schedules) => {
  const fullDateString = getFullDateString(newDate);

  let hasLunchHoursToday = false;

  let closesSoon = false;
  let closedOnMorning = false;
  let closedOnLunch = false;

  let isOpened = false;

  let fromLunchDate = "";
  let toLunchDate = "";

  let closesSoonMinutes = 20;

  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];

  let todayIndex = newDate.getDay();

  const todaySchedule = schedules.attentions?.find(
    (attention) => attention.day === days[todayIndex]
  );
  if (todaySchedule) {
    if (todaySchedule.launchStartTime && todaySchedule.launchEndTime) {
      hasLunchHoursToday = true;
    }

    const fromDate = moment(
      `${fullDateString} ${todaySchedule.startTime}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );

    const toDate = moment(
      `${fullDateString} ${todaySchedule.endTime}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );

    if (hasLunchHoursToday) {
      fromLunchDate = moment(
        `${fullDateString} ${todaySchedule.launchStartTime}:00`,
        "YYYY-MM-DD HH:mm:ss"
      );

      toLunchDate = moment(
        `${fullDateString} ${todaySchedule.launchEndTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    const toDateSoon = moment(
      new Date(toDate.toDate().getTime() - closesSoonMinutes * 60 * 1000),
      "YYYY-MM-DD HH:mm:ss"
    ).tz("America/Santiago");

    let fromLunchDateSoon;
    isNotNill(fromLunchDate) &&
      (fromLunchDateSoon = moment(
        new Date(
          fromLunchDate.toDate().getTime() - closesSoonMinutes * 60 * 1000
        ),
        "YYYY-MM-DD HH:mm:ss"
      ).tz("America/Santiago"));

    if (newDate >= fromDate && newDate < toDate) {
      if (isNotNill(fromLunchDate) && isNotNill(toLunchDate)) {
        if (newDate >= fromLunchDate && newDate < toLunchDate) {
          closedOnLunch = true;
        } else {
          isOpened = true;
          if (
            (newDate >= fromLunchDateSoon && newDate < fromLunchDate) ||
            (newDate >= toDateSoon && newDate < toDate)
          ) {
            closesSoon = true;
          }
        }
      } else {
        isOpened = true;
        if (newDate >= toDateSoon && newDate < toDate) {
          closesSoon = true;
        }
      }
    } else if (newDate < fromDate) {
      closedOnMorning = true;
    }
  }

  let openCloseAt = "";
  let indexDay = todayIndex;

  if (isOpened) {
    openCloseAt =
      hasLunchHoursToday && newDate < fromLunchDate
        ? `Cierra a las ${todaySchedule.launchStartTime}`
        : `Cierra a las ${todaySchedule.endTime}`;
  } else {
    if (!closedOnMorning && !closedOnLunch) {
      indexDay++;
    }

    let counterExit = 0;
    while (openCloseAt === "") {
      if (indexDay > 6) indexDay = 0;

      const scheduleInIndex = schedules.attentions?.find(
        (attention) => attention.day === days[indexDay]
      );

      if (scheduleInIndex) {
        openCloseAt = `Abre ${
          !closedOnMorning && !closedOnLunch ? `el ${scheduleInIndex.day}` : ""
        } a las ${
          closedOnMorning
            ? scheduleInIndex.startTime
            : closedOnLunch
            ? scheduleInIndex.launchEndTime
            : scheduleInIndex.startTime
        } hrs.`;
      } else {
        indexDay++;
      }
      closedOnLunch = false;
      closedOnMorning = false;

      counterExit++;
      if (counterExit > 8) openCloseAt = "no data";
    }
  }

  return (
    (isOpened
      ? closesSoon
        ? "Cierra pronto - "
        : "Abierto - "
      : "Cerrado - ") + openCloseAt
  );
};

export const createPoint = (agency) => {
  if (!agency) return getEmptyPoint();
  const street = agency.address.streetName;
  const city = agency.address.city.name;

  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];

  const schedulesByDay = {};
  const cutHoursByDay = {};

  daysOfWeek.forEach((day) => {
    const schedule = agency.schedules.attentions?.find(
      (attention) => attention.day === day
    );
    schedulesByDay[day.toLowerCase()] = schedule ? schedule : null;
  });

  daysOfWeek.forEach((day) => {
    const cutHours = agency.schedules.cutHours?.find(
      (cutHour) => cutHour.day === day
    );
    cutHoursByDay[day.toLowerCase()] = cutHours ? cutHours : null;
  });

  const point = {
    is_opened: getIsPointOpened(getCurrentDate(), agency.schedules),
    is_santiago:
      agency.address.city.id.length === 5
        ? agency.address.city.id.startsWith("13")
          ? true
          : false
        : false,
    name: agency.agencyName,
    distance: `${agency.distance
      .toFixed(1)
      .toString()
      .replace(/0+$/, "")
      .replace(/\.+$/, "")} km.`,
    address: `${street.charAt(0).toUpperCase()}${street
      .slice(1)
      .toLowerCase()} ${agency.address.streetNumber || ""}, ${city
      .charAt(0)
      .toUpperCase()}${city.slice(1).toLowerCase()}`,

    phone: {
      phoneNumber: agency.phone ? agency.phone.phoneNumber : "-",
      isVisible: agency.phone ? agency.phone.visibility : false,
    },
    schedule: calculateSchedule(getCurrentDate(), agency.schedules), //"Lunes a Viernes de 9:00 a 18:00 hrs.",
    coordenadas: {
      lat: agency.address.geolocation.latitude,
      lng: agency.address.geolocation.longitude,
    },
    services: {
      isPackageAdmission: agency.packageReception,
      isPackageDelivery: agency.pickupAvailability,
      isReturnOfPackages: false,
    },

    scheduleDetail: [
      {
        day: "Domingo",
        indexDay: 0,
        open:
          isNotNill(schedulesByDay["domingo"]) &&
          schedulesByDay["domingo"].startTime,
        close:
          isNotNill(schedulesByDay["domingo"]) &&
          schedulesByDay["domingo"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["domingo"]) &&
          isNotNill(schedulesByDay["domingo"].launchStartTime) &&
          schedulesByDay["domingo"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["domingo"]) &&
          isNotNill(schedulesByDay["domingo"].launchEndTime) &&
          schedulesByDay["domingo"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["domingo"]) &&
          isNotNill(cutHoursByDay["domingo"].hour) &&
          cutHoursByDay["domingo"].hour,
      },
      {
        day: "Lunes",
        indexDay: 1,
        open:
          isNotNill(schedulesByDay["lunes"]) &&
          schedulesByDay["lunes"].startTime,
        close:
          isNotNill(schedulesByDay["lunes"]) && schedulesByDay["lunes"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["lunes"]) &&
          isNotNill(schedulesByDay["lunes"].launchStartTime) &&
          schedulesByDay["lunes"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["lunes"]) &&
          isNotNill(schedulesByDay["lunes"].launchEndTime) &&
          schedulesByDay["lunes"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["lunes"]) &&
          isNotNill(cutHoursByDay["lunes"].hour) &&
          cutHoursByDay["lunes"].hour,
      },
      {
        day: "Martes",
        indexDay: 2,
        open:
          isNotNill(schedulesByDay["martes"]) &&
          schedulesByDay["martes"].startTime,
        close:
          isNotNill(schedulesByDay["martes"]) &&
          schedulesByDay["martes"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["martes"]) &&
          isNotNill(schedulesByDay["martes"].launchStartTime) &&
          schedulesByDay["martes"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["martes"]) &&
          isNotNill(schedulesByDay["martes"].launchEndTime) &&
          schedulesByDay["martes"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["martes"]) &&
          isNotNill(cutHoursByDay["martes"].hour) &&
          cutHoursByDay["martes"].hour,
      },
      {
        day: "Miércoles",
        indexDay: 3,
        open:
          isNotNill(schedulesByDay["miercoles"]) &&
          schedulesByDay["miercoles"].startTime,
        close:
          isNotNill(schedulesByDay["miercoles"]) &&
          schedulesByDay["miercoles"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["miercoles"]) &&
          isNotNill(schedulesByDay["miercoles"].launchStartTime) &&
          schedulesByDay["miercoles"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["miercoles"]) &&
          isNotNill(schedulesByDay["miercoles"].launchEndTime) &&
          schedulesByDay["miercoles"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["miercoles"]) &&
          isNotNill(cutHoursByDay["miercoles"].hour) &&
          cutHoursByDay["miercoles"].hour,
      },
      {
        day: "Jueves",
        indexDay: 4,
        open:
          isNotNill(schedulesByDay["jueves"]) &&
          schedulesByDay["jueves"].startTime,
        close:
          isNotNill(schedulesByDay["jueves"]) &&
          schedulesByDay["jueves"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["jueves"]) &&
          isNotNill(schedulesByDay["jueves"].launchStartTime) &&
          schedulesByDay["jueves"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["jueves"]) &&
          isNotNill(schedulesByDay["jueves"].launchEndTime) &&
          schedulesByDay["jueves"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["jueves"]) &&
          isNotNill(cutHoursByDay["jueves"].hour) &&
          cutHoursByDay["jueves"].hour,
      },
      {
        day: "Viernes",
        indexDay: 5,
        open:
          isNotNill(schedulesByDay["viernes"]) &&
          schedulesByDay["viernes"].startTime,
        close:
          isNotNill(schedulesByDay["viernes"]) &&
          schedulesByDay["viernes"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["viernes"]) &&
          isNotNill(schedulesByDay["viernes"].launchStartTime) &&
          schedulesByDay["viernes"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["viernes"]) &&
          isNotNill(schedulesByDay["viernes"].launchEndTime) &&
          schedulesByDay["viernes"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["viernes"]) &&
          isNotNill(cutHoursByDay["viernes"].hour) &&
          cutHoursByDay["viernes"].hour,
      },
      {
        day: "Sábado",
        indexDay: 6,
        open:
          isNotNill(schedulesByDay["sabado"]) &&
          schedulesByDay["sabado"].startTime,
        close:
          isNotNill(schedulesByDay["sabado"]) &&
          schedulesByDay["sabado"].endTime,
        lunchStart:
          isNotNill(schedulesByDay["sabado"]) &&
          isNotNill(schedulesByDay["sabado"].launchStartTime) &&
          schedulesByDay["sabado"].launchStartTime,
        lunchEnd:
          isNotNill(schedulesByDay["sabado"]) &&
          isNotNill(schedulesByDay["sabado"].launchEndTime) &&
          schedulesByDay["sabado"].launchEndTime,
        pickup:
          isNotNill(cutHoursByDay["sabado"]) &&
          isNotNill(cutHoursByDay["sabado"].hour) &&
          cutHoursByDay["sabado"].hour,
      },
    ],
    dimensions: `Hasta ${agency.maximumPackageDimensions?.length} x ${agency.maximumPackageDimensions?.width} x ${agency.maximumPackageDimensions?.height} cms.`,
    weight: `Hasta ${agency.maximumPackageDimensions.weight} kg.`,
    other_location_info:
      agency.address.reference &&
      agency.address.reference.charAt(0).toUpperCase() +
        agency.address.reference.slice(1).toLowerCase(),
    typeAgency: {
      code: agency.typeAgency.code,
      name: agency.typeAgency.name,
    },
    is24Hours: agency.schedules.fullTime,
    clientServices: agency.services,
    additionalServices: agency.additionalServices,
  };

  return point;
};

export const getEmptyPoint = () => {
  const street = "";
  const city = "";

  const point = {
    is_santiago: false,
    name: "",
    distance: "",

    phone: {
      phoneNumber: "",
      isVisible: false,
    },
    schedule: "",
    coordenadas: {
      lat: "",
      lng: "",
    },
    services: {
      isPackageAdmission: false,
      isPackageDelivery: false,
      isReturnOfPackages: false,
    },

    scheduleDetail: [
      {
        day: "",
        indexDay: 0,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 1,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 2,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 3,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 4,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 5,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
      {
        day: "",
        indexDay: 6,
        open: "",
        close: "",
        lunchStart: "",
        lunchEnd: "",
        pickup: "",
      },
    ],
    dimensions: "",
    weight: "",
    other_location_info: "",
    typeAgency: {
      code: "",
      name: "",
    },
    is24Hours: false,
    clientServices: [],
    additionalServices: [],
  };

  return point;
};

export const getCurrentDate = () => {
  let newDate = moment().tz("America/Santiago").toDate();

  return newDate;
};

export const getDistance = (point1, point2) => {
  let radians = Math.PI / 180;
  let earthRadius = 6371;
  let aux =
    0.5 -
    Math.cos((point2.lat - point1.lat) * radians) / 2 +
    (Math.cos(point1.lat * radians) *
      Math.cos(point2.lat * radians) *
      (1 - Math.cos((point2.lng - point1.lng) * radians))) /
      2;
  return earthRadius * 2 * Math.asin(Math.sqrt(aux));
};

export const sortAgenciesByDistanceAndPriority = (agencies) => {
  let sortedAgencies = agencies?.sort((a, b) => {
    if (a.typeAgency.priority !== b.typeAgency.priority) {
      return a.typeAgency.priority - b.typeAgency.priority;
    } else {
      return a.distance - b.distance;
    }
  });
  sortedAgencies = { agencies: sortedAgencies };
  return sortedAgencies;
};

export const getDistancesAndSortAgencies = (agencies, actualLocation) => {
  if (!agencies) return;

  let agenciesWithDistances = agencies?.map((agency) => {
    const agencyLocation = {
      lat: agency.address.geolocation.latitude,
      lng: agency.address.geolocation.longitude,
    };
    return {
      ...agency,
      distance: getDistance(actualLocation, agencyLocation),
    };
  });

  const sortedAgencies = sortAgenciesByDistanceAndPriority(
    agenciesWithDistances
  );

  return sortedAgencies;
};

export const isNotNill = (object) => {
  if (object && object !== "") return true;
  return false;
};

export const getDayNameByIndex = (index) => {
  switch (index) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miércoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sábado";
    default:
      return "No Válido";
  }
};

export const getFilteredAgenciesV2 = (
  agenciesInBoundariesArray,
  agenciesArray,
  topAgency,
  pointTypesArray,
  clientServicesArray,
  filtersControllerArray,
  additionalServicesArray,
  selectedSwitchButtonValue,
  searchBarText
) => {
  let filteredAgencies = agenciesInBoundariesArray;

  if (selectedSwitchButtonValue === "option-point-name") {
    if (searchBarText !== "" && searchBarText.length > 2 && agenciesArray) {
      filteredAgencies = agenciesArray?.filter((agency) => {
        return agency.agencyName
          .toLowerCase()
          .replace(/[áéíóúü]/g, (match) =>
            match.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          )
          .toLowerCase()
          .includes(
            searchBarText
              .replace(/[áéíóúü]/g, (match) =>
                match.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              )
              .toLowerCase()
          );
      });
    }
  }

  filteredAgencies = filteredAgencies?.filter((agency) => {
    return (
      agency.status === "active" && agency.agencyId !== topAgency?.agencyId
    );
  });

  const pointTypesChecked = pointTypesArray?.filter(
    (pointType) => pointType.checked
  );

  if (filteredAgencies && pointTypesChecked?.length !== 0) {
    filteredAgencies = filteredAgencies?.filter((agency) => {
      return pointTypesChecked?.some(
        (pointType) => pointType.code === agency.typeAgency.code
      );
    });
  }

  const clientServicesChecked = clientServicesArray?.filter(
    (service) => service.checked
  );

  if (clientServicesChecked?.length !== 0) {
    filteredAgencies = filteredAgencies?.filter((agency) => {
      return clientServicesChecked?.every((service) => {
        return agency.services?.map((c) => c.id).includes(service.code);
      });
    });
  }

  const additionalServicesChecked = additionalServicesArray?.filter(
    (service) => service.checked
  );

  if (additionalServicesChecked?.length !== 0) {
    filteredAgencies = filteredAgencies?.filter((agency) => {
      return additionalServicesChecked?.every((service) => {
        return agency.additionalServices
          ?.map((a) => a.id)
          .includes(service.code);
      });
    });
  }

  const filtersControllerChecked = filtersControllerArray?.filter(
    (service) => service.checked
  );
  if (filtersControllerChecked?.length > 0) {
    if (filtersControllerChecked?.some((filter) => filter.code === 2)) {
      filteredAgencies = filteredAgencies?.filter((agency) => {
        const actualSchedule = getActualSchedule(agency);
        if (actualSchedule) {
          return (
            moment(
              `${getFullDateString(getCurrentDate())} ${
                actualSchedule.endTime
              }:00`,
              "YYYY-MM-DD HH:mm:ss"
            ) >
            moment(
              `${getFullDateString(getCurrentDate())} ${
                filtersControllerChecked?.find(
                  (filterController) =>
                    //2 is the code value for Close Late
                    filterController.code === 2
                ).value
              }:00`,
              "YYYY-MM-DD HH:mm:ss"
            )
          );
        }
        return false;
      });
    }
    if (filtersControllerChecked?.some((filter) => filter.code === 1)) {
      filteredAgencies = filteredAgencies?.filter((agency) => {
        const actualSchedule = getActualSchedule(agency);
        if (actualSchedule) {
          return (
            moment(
              `${getFullDateString(getCurrentDate())} ${
                actualSchedule.startTime
              }:00`,
              "YYYY-MM-DD HH:mm:ss"
            ) <
            moment(
              `${getFullDateString(getCurrentDate())} ${
                filtersControllerChecked?.find(
                  (filterController) =>
                    //1 is the code value for Open Early
                    filterController.code === 1
                ).value
              }:00`,
              "YYYY-MM-DD HH:mm:ss"
            )
          );
        }
        return false;
      });
    }
    if (filtersControllerChecked?.some((filter) => filter.code === 3)) {
      filteredAgencies = filteredAgencies?.filter((agency) => {
        return agency.schedules.fullTime;
      });
    }
  }

  return filteredAgencies;
};

export const getEmptyListbox = () => {
  return (
    <div className="p-3">
      <div className="d-flex justify-content-center">
        <img
          src={
            process.env.REACT_APP_IMG_URL + "/map_markers/tracking_empty.png"
          }
          alt="empty-results"
          className="h-100"
          style={{ width: "200px" }}
        />
      </div>
      <div className="fs-18px color-text-blue fw-800 py-2">
        <center>
          <span>Ups! Aléjate en el mapa para ver más Puntos cercanos</span>
        </center>
      </div>
      <div className="fs-14px fw-800">
        <center>
          <span>
            También puedes ingresar el nombre de la comuna para realizar una
            búsqueda más amplia
          </span>
        </center>
      </div>
    </div>
  );
};

export const checkLocationPermission = () => {
  if ("permissions" in navigator) {
    return navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => permissionStatus.state);
  } else {
    return Promise.resolve("unsupported");
  }
};

export const setActualGeolocation = (setActualLocation, setCenter, center) => {
  let newCenter = center;
  if ("geolocation" in navigator) {
    console.log("Geolocation Available");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        newCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        setActualLocation(newCenter);
        setCenter(newCenter);
      },
      (error) => {
        console.log("Geolocation Error Callback");
        if (error.code === error.PERMISSION_DENIED) {
          showMsgBlockedPermissions();
        }
        console.error(error);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 5000,
      }
    );
  } else {
    console.log("Browser does not support the Geolocation API");
  }
  return newCenter;
};

export const showMsgBlockedPermissions = () => {
  var mensaje =
    "Los permisos de ubicación están bloqueados. Si deseas usar los servicios de ubicación, por favor, sigue estos pasos para habilitarlos:\n\n";

  // Agregar los pasos para habilitar los permisos según el navegador
  if (/Chrome\/(\d+)/.test(navigator.userAgent)) {
    mensaje +=
      "1. Haz clic en el icono de ubicación con una X roja situado a la derecha en la barra de búsquedas.\n";
    mensaje += '2. Selecciona "Permitir" y acepta.\n';
    mensaje += '3. Intenta nuevamente presionando el botón de ubicación".\n';
  } else if (/Firefox\//.test(navigator.userAgent)) {
    mensaje +=
      "1. Haz clic en el icono de ubicación tachado situado a la izquierda en la barra de búsquedas.\n";
    mensaje +=
      '2. Elimina el bloqueo del permiso de ubicación pulsando en la X dónde dice "bloqueado".\n';
    mensaje += "3. Intenta nuevamente presionando el botón de ubicación.\n";
  } else {
    mensaje +=
      "Por favor, habilita los permisos de ubicación en la configuración de tu navegador.\n";
  }

  alert(mensaje);
};
