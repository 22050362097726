import React from "react";
import { RefreshCcw, X } from "feather-icons-react/build/IconComponents";
import SwitchButton from "./SwitchButton";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";

const SideFilters = ({
  pointTypesArray,
  setPointTypesCheck,
  clientServicesArray,
  setClientServicesCheck,
  filtersControllerArray,
  setFilterControllerCheck,
  otherFiltersArray,
  setOtherFilterCheck,
  setMenuOpen,
  selectedSwitchButtonValue,
  setSelectedSwitchButtonValue,
  resetFilters,
  isOpen,
}) => {
  return (
    <div className={"side-filters-full-container" + (isOpen ? " open" : "")}>
      <div className={"side-filters-container" + (isOpen ? " open" : "")}>
        <div className="d-flex align-items-center justify-content-between pb-3">
          <span className="fs-18px fw-800">Otros filtros</span>
          <div className="cursor-pointer" onClick={() => setMenuOpen(false)}>
            <X size={24} />
          </div>
        </div>
        <div className="d-flex flex-column gap-3 overflow-auto h-100 pb-5">
          <div className="hide-on-desktop-and-tablet">
            <span className="fw-800">Búsqueda por</span>
            <div className="pt-2 ps-2 gap-2">
              <SwitchButton
                selectedButtonValue={selectedSwitchButtonValue}
                setSelectedButtonValue={setSelectedSwitchButtonValue}
              />
            </div>
          </div>
          <div className="hide-on-desktop-and-tablet">
            <span className="fw-800">Tipos de Punto</span>
            <div className="pt-2 d-flex flex-wrap gap-2">
              {pointTypesArray?.map(
                (pointType) =>
                  pointType.visibility && (
                    <CheckFilterButton
                      key={pointType.description}
                      text={pointType.description}
                      checked={pointType.checked}
                      code={pointType.code}
                      setFilterCheck={setPointTypesCheck}
                    />
                  )
              )}
            </div>
          </div>
          <div className="hide-on-desktop-and-tablet">
            <span className="fw-800">Servicios</span>
            <div className="pt-2 d-flex flex-wrap gap-2">
              {clientServicesArray?.map((service) => (
                <CheckFilterButton
                  key={service.description}
                  text={service.description}
                  checked={service.checked}
                  code={service.code}
                  setFilterCheck={setClientServicesCheck}
                />
              ))}
            </div>
          </div>
          <div>
            <span className="fw-800">Filtros de horario</span>
            <div className="pt-2 d-flex flex-wrap gap-2">
              {filtersControllerArray?.map((filter) => (
                <CheckFilterButton
                  key={filter.filterName}
                  text={filter.filterName}
                  checked={filter.checked}
                  code={filter.code}
                  setFilterCheck={setFilterControllerCheck}
                />
              ))}
            </div>
          </div>
          <div>
            <span className="fw-800">Otros Filtros</span>
            <div className="pt-2 d-flex flex-wrap gap-2">
              {otherFiltersArray?.map((filter) => (
                <CheckFilterButton
                  key={filter.description}
                  text={filter.description}
                  checked={filter.checked}
                  code={filter.code}
                  setFilterCheck={setOtherFilterCheck}
                />
              ))}
            </div>
          </div>
          <div className="pt-2">
            <button
              className="btn btn-options-white w-100 details fs-14px fw-700 "
              onClick={() => resetFilters()}
            >
              <div className="px-2 hide-on-desktop-and-tablet">
                <RefreshCcw size={16} />
              </div>
              Limpiar Filtros
            </button>
          </div>
          <div className="pb-2">
            <button
              className="btn btn-options w-100 details fs-14px fw-700 "
              onClick={() => setMenuOpen(false)}
            >
              <div className="px-2 hide-on-desktop-and-tablet">
                <PlusCircle size={16} />
              </div>
              Aplicar Filtros
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckFilterButton = ({ text, checked, code, setFilterCheck }) => {
  return (
    <div
      className={
        "cursor-pointer " +
        (checked ? "side-filter-button-checked " : " side-filter-button")
      }
      onClick={() => setFilterCheck(!checked, code)}
    >
      <span>{text}</span>
    </div>
  );
};

export default SideFilters;
